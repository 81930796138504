@use "styles/includes.scss" as i;

.pane {
  $pane: &;
  background-color: i.$pane--background-color;
  border-radius: i.$border-radius--normal;
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: repeat(var(--pane-size), var(--dimention));
  gap: var(--indent);
  max-width: 520px;
  margin: 0 auto;

  font-size: var(--font-size);
  padding: var(--indent);

  > * {
    float: left;
    background-color: i.$tile-empty--background-color;
    border-radius: i.$border-radius--small;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    width: var(--dimention);
    height: var(--dimention);

    &:not(:nth-child(3n)) {
      margin-right: var(--indent);
    }
  }

  &__wrapper {
    order: 1;
    position: relative;

    &[data-game-over] > #{$pane}__gameOver {
      visibility: visible;
      opacity: 1;
      transition: opacity 1s ease-out;
      transition-delay: 1s;
    }
  }

  &__gameOver {
    @include i.setRect(0, 0, 0, 0);
    align-items: center;
    background-color: i.$pane-gameOver--background-color;
    color: i.$title--color;
    display: flex;
    font-size: 3.4rem;
    font-weight: 700;
    justify-content: center;
    opacity: 0;
    position: absolute;
    visibility: hidden;
  }
}
